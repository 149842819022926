import React, { createContext, useState } from "react";

interface LoginContextType {
  loggedIn: boolean;
  login: () => void;
  logout: () => void;
}

export const LoginContext = createContext<LoginContextType | null>(null);

interface LoginContextProviderProps {
  children: React.ReactNode;
}

const LoginContextProvider: React.FC<LoginContextProviderProps> = ({
  children,
}) => {
  const [loggedIn, setLoggedIn] = useState(false);

  const login = () => {
    // Perform your login logic here
    setLoggedIn(true);
  };

  const logout = () => {
    // Perform your logout logic here
    localStorage.removeItem("xSudTzMYmd");
    setLoggedIn(false);
  };

  const contextValue: LoginContextType = {
    loggedIn,
    login,
    logout,
  };

  return (
    <LoginContext.Provider value={contextValue}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
