import "../styles/OutletLoading.css";

interface LoaderProps {}

const OutletLoader: React.FC<LoaderProps> = ({}) => {
  return (
    <div className="loading-container flex h-screen flex-col items-center justify-center bg-white">
      <div className="spinner"></div>
    </div>
  );
};

export default OutletLoader;
