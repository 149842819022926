import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  deleteCampaign,
  getAllCampaigns,
} from "../../features/Campaigns/campaignSlice";
import { useAppDispatch } from "../../app/hooks";

interface DeleteProjectProps {
  id: string;
  close: () => void;
  onDelete?: () => void;
}

const DeleteProject: React.FC<DeleteProjectProps> = ({
  id,
  close,
  onDelete,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = (id: string) => {
    dispatch(deleteCampaign(id)).then(() => {
      dispatch(getAllCampaigns());
      close();
      navigate("/dashboard/campaigns");
    });
  };

  return (
    <div className=" fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-y-auto overflow-x-hidden transition">
      <div className=" z-10 flex w-screen bg-transparent backdrop-blur-sm sm:h-screen"></div>
      <div className="absolute left-1/2 top-1/2 z-20 flex w-80 -translate-x-1/2 -translate-y-1/2  transform flex-col items-center justify-center gap-y-4 rounded-lg border-[2px] border-[#e3e6eb] bg-[#ffffff] bg-opacity-95 p-0 sm:w-auto ">
        <div className="flex w-full flex-row justify-between rounded-t-md border-b-[1px] border-[#e3e6eb] bg-[#fafaf9] p-4">
          <p className=" font-poppins text-base font-semibold text-black">
            Delete project
          </p>
          <FontAwesomeIcon
            onClick={() => close()}
            icon={faClose}
            className="h-5 cursor-pointer"
          ></FontAwesomeIcon>
        </div>

        <div className="flex w-full flex-col items-start justify-start text-wrap px-4 py-2 sm:px-6">
          <h2 className="mb-1 w-full text-start  font-poppins font-semibold text-black sm:text-lg ">
            Are you sure you want to delete this project ?
          </h2>
          <p className=" w-full text-start font-poppins text-sm sm:text-base">
            This action is permanent and cannot be undone.{" "}
          </p>
        </div>
        <div className="flex w-full justify-center gap-x-2 px-4 pb-8 sm:gap-x-4">
          <button
            onClick={() => handleDelete(id)}
            className="w-28 cursor-pointer rounded-md bg-black px-3 py-2 text-center font-poppins  text-sm  font-medium text-white hover:scale-105 sm:w-28 lg:text-[15px] lg:text-base"
          >
            Delete
          </button>
          <button
            onClick={() => close()}
            className="w-28  cursor-pointer rounded-md border-[2px]  border-[#e3e6eb] bg-[#fafaf9] px-3 py-2 text-center font-poppins  text-sm  font-medium text-black hover:scale-105 sm:w-28 lg:text-[15px] lg:text-base"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProject;
