import { useEffect, useState } from "react";
import SignUp from "./SignUp";
import SignUpEmail from "./SignUpEmail";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const SignUpContainer: React.FC = () => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const displayInfoMessage = (message: string) => {
    setInfoMessage(message);
    setTimeout(() => {
      setInfoMessage(null);
    }, 3000);
    toast.error(message, {
      style: {
        fontSize: "1.2rem",
      },
    });
  };

  const handleEmailButtonClick = () => {
    setShowEmailForm(true);
  };

  const handleGoBack = () => {
    setShowEmailForm(false);
  };

  useEffect(() => {
    if (localStorage.getItem("xSudTzMYmd")) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <div className="h-screen">
      <Toaster />
      {showEmailForm ? (
        <SignUpEmail
          onGoBack={handleGoBack}
          displayInfoMessage={displayInfoMessage}
        />
      ) : (
        <SignUp onEmailButtonClick={handleEmailButtonClick} />
      )}
    </div>
  );
};

export default SignUpContainer;
