interface FreeTrailProps {
  close: React.Dispatch<React.SetStateAction<boolean>>;
}

const FreeTrail: React.FC<FreeTrailProps> = ({ close }) => {
  return (
    <div className=" fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-y-auto overflow-x-hidden  transition  ">
      <div className=" z-10   flex h-screen w-screen bg-transparent backdrop-blur-sm  "></div>
      <div className="  absolute   left-1/2 top-1/2 z-20 flex w-72 -translate-x-1/2 -translate-y-1/2  transform flex-col items-center  justify-center    gap-y-4  rounded-lg  border-[2px]   border-[#e3e6eb]   bg-[#ffffff] bg-opacity-95 p-0 sm:w-auto ">
        <div className="flex w-full flex-row justify-between rounded-t-md border-b-[1px] border-[#e3e6eb] bg-[#fafaf9] p-4">
          <p className="  font-poppins text-base font-semibold text-black">
            Free trail
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start text-wrap  p-5">
          <h2 className="mb-1 w-full text-start   font-poppins text-lg font-semibold text-black ">
            The game will be active for 24 hours from the start <br /> date
            provided in free trail.
          </h2>
          <p className=" w-full text-start font-poppins text-base">
            To modify the end date, please get in touch with us.
          </p>
        </div>
        <div className="mb-2 flex w-full flex-row justify-center  p-4 sm:gap-x-8 ">
          <button
            onClick={() => {
              close(false);
            }}
            className=" w-28 cursor-pointer rounded-md  bg-black px-3 py-2 text-center font-poppins  text-sm  font-medium text-white hover:scale-105 sm:w-48 lg:text-[15px] lg:text-base"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default FreeTrail;
