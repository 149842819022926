import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { HashLink } from "react-router-hash-link";
import UserAvatar from "./UserProfile/UserAvatar";
import { LoginContext } from "./Context/LoginContext/LoginContext";
import { userContext } from "./Context/LoginContext/UserContext";
import LazyLoadImages from "./LazyLoadImages";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const contextValue = useContext(LoginContext);
  const { user, setUser } = useContext(userContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    const bodyElement = document.body;
    bodyElement.classList.toggle("mobile-menu-open", isMobileMenuOpen);
    const gameCards = document.querySelectorAll(".game-card");
    const socials = document.querySelectorAll("#footer");
    const contact = document.querySelectorAll("#contact");
    gameCards.forEach((card) => {
      if (card instanceof HTMLElement) {
        card.style.display = isMobileMenuOpen ? "block" : "none";
      }
    });
    socials.forEach((card) => {
      if (card instanceof HTMLElement) {
        card.style.display = isMobileMenuOpen ? "block" : "none";
      }
    });
    contact.forEach((card) => {
      if (card instanceof HTMLElement) {
        card.style.display = isMobileMenuOpen ? "block" : "none";
      }
    });
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("formData");
    localStorage.removeItem("xSudTzMYmd");
    contextValue?.logout();
    sessionStorage.removeItem(" LazyLoadImagesLink");
    sessionStorage.removeItem("fname");
    sessionStorage.removeItem("lname");
    sessionStorage.removeItem("collapse");
    sessionStorage.removeItem("email");
    setIsMobileMenuOpen(false);
  };

  const handleProfile = () => {
    navigate("dashboard/profile");
    setIsMobileMenuOpen(false);
  };

  const handleRedirectToDashboard = () => {
    navigate("/dashboard/campaigns");
    setIsMobileMenuOpen(false);
  };

  const handleSetUser = (data: any) => {
    setUser({
      email: data.email,
      fname: data.firstName,
      lname: data.lastName,
      phone: data.phoneNumber,
      isdcode: data.isdCode,
      img: {
        source: data.LazyLoadImages?.source ?? "default", // Use optional chaining and default value
        link:
          data.LazyLoadImages?.source === "bucket"
            ? `${REACT_APP_S3_URL}/uploads/profile/${data.LazyLoadImages?.link}`
            : data.LazyLoadImages?.link ?? "", // Default to an empty string if link is missing
      },
      bio: data.bio,
      location: data.location,
      socials: data.socials,
      company: data.company,
    });
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const token = localStorage.getItem("xSudTzMYmd");

    const fetchProfile = async (retry = false) => {
      const URL = `${REACT_APP_API_URL}/auth/profile`;
      if (!URL) {
        return;
      }

      try {
        const response = await axios.get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success === true) {
          contextValue?.login();
          const userData = response.data.data;

          // Check if LazyLoadImages exists before accessing its properties
          if (userData?.LazyLoadImages?.source) {
            handleSetUser(userData);

            if (userData.LazyLoadImages.source === "oauth") {
              localStorage.setItem(
                "LazyLoadImagesLink",
                userData.LazyLoadImages.link,
              );
            } else if (userData.LazyLoadImages.source === "bucket") {
              localStorage.setItem(
                "LazyLoadImagesLink",
                `${REACT_APP_S3_URL}/uploads/profile/${userData.LazyLoadImages.link}`,
              );
            }
          } else {
            console.log("LazyLoadImages data is missing");

            // Retry fetching after a delay if LazyLoadImages is missing
            if (!retry) {
              setTimeout(() => fetchProfile(true), 1000);
            }
          }
        }
      } catch (error: any) {
        contextValue?.logout();
        localStorage.clear();
        sessionStorage.clear();
        console.log(error);
      }
    };

    if (token) {
      fetchProfile();
    }
  }, []);

  const handleLogoRedirect = () => {
    navigate("/");
  };
  const handleLogin = () => {
    setTimeout(() => {
      navigate("/login");
    }, 400);
    setIsMobileMenuOpen(false);
  };

  const handleSignUp = () => {
    setTimeout(() => {
      navigate("/signup");
    }, 400);
    setIsMobileMenuOpen(false);
  };

  return (
    <section
      id="navbar"
      className="flex items-center justify-between bg-[#1e1e1e] px-40 py-5 text-white"
    >
      <div className="menubar">
        <LazyLoadImages
          className="h-6 cursor-pointer lg:h-7"
          src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
          alt="workplay-logo"
          onClick={handleLogoRedirect}
        />

        <div
          className={`burger ${isMobileMenuOpen ? "open" : ""}`}
          onClick={toggleMobileMenu}
        >
          <div className={`line  ${isMobileMenuOpen ? "open" : ""}`}></div>
          <div className={`line  ${isMobileMenuOpen ? "open" : ""}`}></div>
          <div className={`line ${isMobileMenuOpen ? "open" : ""}`}></div>
        </div>
      </div>
      <div className="navItems-btns">
        <ul className={`nav-items ${isMobileMenuOpen ? "open" : ""} `}>
          <Link to="/" onClick={closeMobileMenu}>
            <li>Home</li>
          </Link>
          <HashLink to="/#about" onClick={closeMobileMenu}>
            <li>About Us</li>
          </HashLink>
          <HashLink to="/#what-we-do" onClick={closeMobileMenu}>
            <li>What we do</li>
          </HashLink>
          <HashLink to="/#our-services" onClick={closeMobileMenu}>
            <li>Our Services</li>
          </HashLink>
          {/* <HashLink to="/plans" onClick={closeMobileMenu}>
            <li>Pricing</li>
          </HashLink> */}
          <Link to="/contact" onClick={closeMobileMenu}>
            <li>Contact</li>
          </Link>
          {!localStorage.getItem("xSudTzMYmd") ? (
            <div className="login-signUp">
              <button
                className="login-btn w-[100px] cursor-pointer rounded-full border border-black bg-apple py-2 text-base font-medium text-black shadow-navBtn"
                onClick={handleLogin}
              >
                Login
              </button>
              <button
                className="signUp-btn ml-3 w-[100px] cursor-pointer rounded-full border border-black bg-apple py-2 text-base font-medium text-black shadow-navBtn"
                onClick={handleSignUp}
              >
                Sign Up
              </button>
            </div>
          ) : !isMobileMenuOpen && localStorage.getItem("xSudTzMYmd") ? (
            <UserAvatar />
          ) : (
            <>
              <li onClick={handleProfile}>Profile</li>
              <li onClick={handleRedirectToDashboard}>Dashboard</li>
              <Link to="/" onClick={handleLogout}>
                <li className="nav-logout">Logout</li>
              </Link>
            </>
          )}
        </ul>
      </div>
    </section>
  );
};

export default Navbar;
