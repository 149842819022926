import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const settings = [
    {
      category: "Roles & Permissions",
      description:
        "Invite people to manage brands and campaigns and set their permissions",
    },
    {
      category: "Subscription Settings",
      description:
        "Effortlessly handle your subscriptions and payment preferences.",
    },
    {
      category: "Manage Plan",
      description:
        "Explore and compare subscription plans, and easily upgrade to the one that suits your needs best.",
    },
    {
      category: "Notification Preferences",
      description:
        "Personalize your notification settings according to your preferences.",
    },
    {
      category: "Invoices Tracking",
      description:
        "Keep track of invoices related to your subscriptions and payments.",
    },
    {
      category: "Manage Inbox",
      description: "Efficiently organize and manage your incoming emails.",
    },
  ];

  return (
    <div className="flex  flex-col  justify-center gap-y-6 bg-eerie-black p-10 ">
      <h1 className="mb-3 bg-eerie-black text-3xl font-semibold text-apple">
        Settings
      </h1>
      <div className=" flex flex-col  gap-y-4">
        {settings.map((setting, index) => (
          <div
            className="cursor-pointer rounded-lg bg-dark-charcoal p-6 hover:opacity-80"
            key={index}
          >
            <div className="font  mb-2 text-xl font-bold text-apple">
              <FontAwesomeIcon
                className=" mr-2  text-apple"
                icon={faShieldHalved}
              ></FontAwesomeIcon>
              {setting.category}{" "}
            </div>
            <p className="  text-white ">{setting.description}</p>{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
