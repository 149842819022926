import React, { useRef } from "react";
import { useEffect, useState, useLayoutEffect } from "react";
import {
  AllCampaigns,
  getAllCampaigns,
  getCampaign,
  deleteCampaign,
  setCurrentCampaign,
  CurrentCampaign,
} from "../../../features/Campaigns/campaignSlice";
import LazyLoadImages from "../../LazyLoadImages";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BarChart2, Calendar, Eye, Link, Trash } from "react-feather";
import * as XLSX from "xlsx";

import {
  faDotCircle,
  faEllipsisVertical,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import DeleteProject from "../../PopUps/DeleteProject";
import Loader from "../../Loader";
import { FaCalendarAlt } from "react-icons/fa";

const {
  REACT_APP_S3_URL,
  REACT_APP_CUSTOM_QUIZ_URL,
  REACT_APP_CUSTOM_SPIN_URL,
} = process.env;

interface Campaign {
  _id: string;
  name: string;
  game: string;
  gameId?: string;
  startDate: string;
  endDate: string;
  status: string;
  updatedAt: string;
  userId: string;
  __v: number;
}

interface CampaignCardProps {
  campaign: Campaign;
  onCopyLink: (id: string) => void;
}

const CampaignCard: React.FC<CampaignCardProps> = ({
  campaign,
  onCopyLink,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDelete, setShowDelete] = useState(false);

  const calculateStatus = (startDate: string, endDate: string): string => {
    const currentDate = new Date();
    const start = new Date(startDate);
    start.setDate(start.getDate() - 1);
    const end = new Date(endDate);

    if (currentDate < start) {
      return "Not Started";
    } else if (currentDate >= start && currentDate <= end) {
      return "In Progress";
    } else {
      return "Completed";
    }
  };

  const status = calculateStatus(campaign.startDate, campaign.endDate);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Not Started":
        return "bg-red-100 py-1 px-5 rounded-full text-red-700";
      case "In Progress":
        return "bg-blue-100 py-1 px-5 rounded-full text-blue-700";
      case "Completed":
        return "bg-green-100 py-1 rounded-full px-5 text-green-700";
      default:
        return "";
    }
  };

  const handleView = (id: string) => {
    dispatch(getCampaign(id)).then(() => {
      setCurrentCampaign(CurrentCampaign);
      navigate(`/dashboard/gamedetails?ccid=${campaign._id}`);
    });
  };

  const confirmDelete = (id: string) => {
    dispatch(deleteCampaign(id)).then(() => {
      dispatch(getAllCampaigns());
    });
  };

  const convertUTCtoIST = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const adjustStartDate = (startDate: string) => {
    const adjustedStartDate = startDate ? new Date(startDate) : null;
    if (adjustedStartDate) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
      return convertUTCtoIST(adjustedStartDate.toISOString());
    }
    return startDate;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div>
      <div className="mb-4 cursor-pointer rounded-lg border border-white bg-white p-4 drop-shadow">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3">
            <h3 className="text-xl font-semibold">{campaign.name}</h3>{" "}
          </div>
          <div className="flex items-center gap-x-2">
            <p
              className={`mx-1 rounded-full px-3 py-1 text-[13px] font-medium ${getStatusStyle(status)}`}
            >
              {status}
            </p>

            <div className="relative" ref={dropdownRef}>
              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen((prev) => !prev);
                }}
                className="cursor-pointer"
                icon={faEllipsisVertical}
              />
              {isOpen && (
                <ul
                  onMouseLeave={() => setIsOpen(false)}
                  className={`dropdown-menu absolute -right-2 -top-2 w-36 rounded-lg border border-gray-200 bg-white p-1 text-sm shadow-md`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <li
                    className="flex items-center space-x-2 p-1 hover:bg-gray-100"
                    onClick={() => handleView(campaign._id)}
                  >
                    <Eye className="h-3 w-3" />
                    <span>View</span>
                  </li>
                  <li className="border-t border-gray-200"></li>
                  <li
                    className="flex items-center space-x-2 p-1 hover:bg-gray-100"
                    onClick={() => setShowDelete(true)}
                  >
                    <Trash className="h-3 w-3" />
                    <span>Delete</span>
                  </li>
                  <li className="border-t border-gray-200"></li>
                  <li
                    className="flex items-center space-x-2 p-1 hover:bg-gray-100"
                    onClick={() => onCopyLink(campaign._id)}
                  >
                    <Link className="h-3 w-3" />
                    <span>Copy Link</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-end">
          <p className="my-1 text-sm font-medium">{campaign.game}</p>
        </div>
        <div className="mt-6 flex items-center justify-between text-stone-500">
          <p className="mb-2 flex items-center text-sm">
            <FaCalendarAlt className="mr-1" size={14} />
            {adjustStartDate(campaign.startDate)}
          </p>
          <p className="mb-2 flex items-center text-sm">
            <Calendar className="mr-1" size={14} />{" "}
            {convertUTCtoIST(campaign.endDate)}
          </p>
        </div>
        <div className="mb-[3px] h-[2px] rounded-md bg-gray-200">
          <div
            className={`h-full ${
              status === "In Progress"
                ? "bg-blue-400"
                : status === "Completed"
                  ? "bg-green-500"
                  : "bg-gray-300"
            }`}
            style={{
              width: `${
                status === "Not Started"
                  ? 0
                  : status === "Completed"
                    ? 100
                    : Math.ceil(
                        ((new Date().getTime() -
                          new Date(campaign.startDate).getTime()) /
                          (new Date(campaign.endDate).getTime() -
                            new Date(campaign.startDate).getTime())) *
                          100,
                      )
              }%`,
            }}
          ></div>
        </div>
      </div>
      {showDelete && (
        <DeleteProject
          id={campaign._id}
          close={() => setShowDelete(false)}
          onDelete={() => confirmDelete(campaign._id)}
        />
      )}{" "}
    </div>
  );
};

const Campaigns: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Campaigns = useAppSelector(AllCampaigns);
  const [filterStatus, setFilterStatus] = useState<string>("All");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [showdelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const handleCreateCampaign = () => {
    dispatch(setCurrentCampaign({}));
    navigate("/dashboard/campaigns/create");
  };

  const handleEdit = (id: string, campaign: Campaign) => {
    dispatch(getCampaign(id)).then(() => {
      setCurrentCampaign(campaign);
      navigate(`/dashboard/campaigns/create?ccid=${campaign._id}`);
    });
  };

  const handleView = (id: string) => {
    dispatch(getCampaign(id)).then(() => {
      setCurrentCampaign(CurrentCampaign);
      navigate(`/dashboard/gamedetails?ccid=${id}`);
    });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStatus = event.target.value;
    setFilterStatus(selectedStatus);
  };

  const filterCampaigns = (status: string) => {
    const campaignsWithStatus = Campaigns.map((campaign: any) => ({
      ...campaign,
      status: calculateStatus(campaign.startDate, campaign.endDate),
    }));

    if (status === "All") {
      return Campaigns;
    } else {
      const filtered = campaignsWithStatus.filter(
        (campaign) => campaign.status === status,
      );
      return filtered;
    }
  };

  // Utility function to sort campaigns
  const sortCampaigns = (campaigns: any, direction: string): Campaign[] => {
    const sorted = [...campaigns].sort((a, b) => {
      // Assuming the Campaign object has some property to sort on, like name
      // Here we are sorting based on the campaign name. You can modify this as needed.
      if (direction === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    return sorted;
  };

  // Function to toggle sorting direction
  const toggleSortDirection = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
  };

  // Check if the current location path includes 'create'
  const isCreateRoute = location.pathname.includes(
    "/dashboard/campaigns/create" || "/dashboard/campaigns/campaignDetails",
  );

  const calculateStatus = (startDate: string, endDate: string): string => {
    const currentDate = new Date();
    const start = new Date(startDate);
    start.setDate(start.getDate() - 1);
    const end = new Date(endDate);

    if (currentDate < start) {
      return "Not Started";
    } else if (currentDate >= start && currentDate <= end) {
      return "In Progress";
    } else {
      return "Completed";
    }
  };

  const convertUTCtoIST = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const convertUTCtoISTwithTime = (dateString: any) => {
    const date = new Date(dateString);

    // Convert UTC date to IST date
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const istDate = new Date(date.getTime() + istOffset);

    const day = istDate.getDate().toString().padStart(2, "0");
    const month = (istDate.getMonth() + 1).toString().padStart(2, "0");
    const year = istDate.getFullYear();

    const hours = istDate.getHours().toString().padStart(2, "0");
    const minutes = istDate.getMinutes().toString().padStart(2, "0");
    const seconds = istDate.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const adjustStartDate = (startDate: string) => {
    const adjustedStartDate = startDate ? new Date(startDate) : null;
    if (adjustedStartDate) {
      return adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
    }
  };

  useEffect(() => {
    dispatch(getAllCampaigns());
  }, []);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Not Started":
        return "bg-red-100 py-1 px-5 rounded-full text-red-700";
      case "In Progress":
        return "bg-blue-100 py-1 px-5 rounded-full text-blue-700";
      case "Completed":
        return "bg-green-100 py-1 rounded-full px-5 text-green-700";
      default:
        return "";
    }
  };

  const handleCopy = (id: string) => {
    const element = Campaigns.find((item) => item.id === id);
    if (element?.gameId) {
      if (element.game === "spin-the-wheel") {
        navigator.clipboard.writeText(
          `${REACT_APP_CUSTOM_SPIN_URL}/play?gameId=${element.gameId}`,
        );
      } else {
        navigator.clipboard.writeText(
          `${REACT_APP_CUSTOM_QUIZ_URL}/play?campaignId=${element.gameId}`,
        );
      }
      toast.info("Link copied to clipboard!", {
        autoClose: 1000,
        className: "toastify-toast",
      });
    } else {
      toast.info("Link not available yet!", {
        autoClose: 1000,
        className: "toastify-toast",
      });
    }
  };

  useLayoutEffect(() => {
    dispatch(getAllCampaigns());
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location.pathname, filterStatus]);

  return (
    <>
      {!isCreateRoute && !loading && (
        <div className=" m-auto block p-6 lg:p-10">
          <div className="h-full">
            {Campaigns.length < 1 ? (
              <>
                <div className="flex w-full justify-end">
                  <div
                    className="mb-6 flex w-fit cursor-pointer items-center rounded-md bg-black px-3 py-2 text-sm font-medium text-white hover:scale-105 lg:text-base"
                    onClick={handleCreateCampaign}
                  >
                    <FontAwesomeIcon
                      className="mr-3 h-4"
                      icon={faPlus}
                    ></FontAwesomeIcon>
                    <p className="font-poppins text-sm lg:text-[15px]">
                      Create project
                    </p>
                  </div>
                </div>
                <div className="m-auto w-full text-center">
                  <p className="mb-2 font-poppins text-[17px] lg:text-lg">
                    Nothing on the game radar!
                  </p>
                  <p className="mb-2 font-poppins text-[15px] text-stone-500 lg:text-base">
                    Let's get started on one now!
                  </p>
                  <LazyLoadImages
                    src={`${REACT_APP_S3_URL}/assets/campaign.webp`}
                    className="m-auto mt-8 h-56 text-center lg:h-[340px]"
                    alt="campaign"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col-reverse items-end justify-end gap-x-2 gap-y-2 lg:flex lg:flex-row lg:items-center lg:gap-x-6">
                  <div className="flex items-center gap-x-3">
                    <button
                      onClick={toggleSortDirection}
                      className="flex items-center gap-x-1 rounded-md border border-gray-300 bg-white px-3 py-2 lg:gap-x-2 "
                    >
                      <LazyLoadImages
                        src={`${REACT_APP_S3_URL}/assets/${sortDirection === "asc" ? "arrow-up" : "down-arrow"}.webp`}
                        alt="arrow"
                        className="h-4"
                      />
                      Sort
                    </button>
                    <div className="flex items-center rounded-md border border-gray-300 bg-white">
                      <LazyLoadImages
                        src={`${REACT_APP_S3_URL}/assets/icons/filter.webp`}
                        className="m-auto h-4 pl-2 text-center"
                        alt="filter"
                      />
                      <select
                        className="ml-2 cursor-pointer rounded-md border-none py-2 pr-2 text-[15px] outline-none"
                        value={filterStatus}
                        onChange={handleFilterChange}
                        title="Status"
                      >
                        <option value="All">All</option>
                        <option value="Not Started">Not Started</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="mr-0 flex w-fit cursor-pointer items-center rounded-md bg-black px-3 py-2 text-sm font-medium text-white hover:scale-105 lg:mr-2 lg:text-base"
                    onClick={handleCreateCampaign}
                  >
                    <FontAwesomeIcon
                      className="mr-3 h-4"
                      icon={faPlus}
                    ></FontAwesomeIcon>
                    <p className="font-poppins text-sm lg:text-[15px]">
                      Create project
                    </p>
                  </div>
                </div>
                <div className="mb-4 mt-4 flex items-center justify-between lg:mb-6 lg:mt-0">
                  <p className="font-poppins text-xl font-semibold">
                    {filterStatus} Projects
                  </p>
                  <div
                    className="flex items-center justify-center rounded-md bg-gray-200 px-2 py-1 text-[15px] sm:hidden"
                    onClick={() => navigate("/dashboard/statistics")}
                  >
                    <BarChart2 size={16} />
                    <p className="ml-1">View Statistics</p>
                  </div>
                </div>
                {filterCampaigns(filterStatus).length === 0 &&
                !isCreateRoute ? (
                  <div className="flex items-center justify-center">
                    <div className="m-auto w-full text-center">
                      <p className="mt-7 font-poppins font-normal">
                        Nothing found based on the filter!
                      </p>
                      <LazyLoadImages
                        src={`${REACT_APP_S3_URL}/assets/nothing-here.webp`}
                        className="m-auto h-[340px] text-center"
                        alt="nothing-here"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="grid grid-cols-1 sm:hidden">
                      {filterCampaigns(filterStatus).map((campaign) => (
                        <CampaignCard
                          key={campaign._id}
                          campaign={campaign}
                          onCopyLink={handleCopy}
                        />
                      ))}
                    </div>

                    <div className="max-[380px]:[20rem] hidden w-[17rem] overflow-auto max-[750px]:w-[43rem] max-[720px]:w-[41rem] max-[690px]:w-[38.5rem] max-[650px]:w-[35rem] max-[600px]:w-[32rem] max-[550px]:w-[28rem] max-[480px]:w-[25rem] max-[420px]:w-[22rem] sm:block md:w-full">
                      <table className="w-full border-collapse overflow-auto text-nowrap border border-gray-200 text-left text-[15px] lg:text-base">
                        <thead>
                          <tr className="bg-gray-200">
                            <th className="px-4 py-2">Name</th>
                            <th className="px-4 py-2">Type</th>
                            <th className="px-4 py-2">Start Date</th>
                            <th className="px-4 py-2">End Date</th>
                            <th className="px-4 py-2">% Complete</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody className="cursor-pointer">
                          {sortCampaigns(
                            filterCampaigns(filterStatus),
                            sortDirection,
                          ).map((campaign) => {
                            // Calculate status based on start date and end date
                            const calculateStatus = () => {
                              const currentDate = new Date();

                              const startDate = new Date(campaign.startDate); // new Date(campaign.startDate) this sets one day extra to actual data
                              startDate.setDate(startDate.getDate() - 1); //removing the extra day
                              const endDate = new Date(campaign.endDate);

                              if (currentDate < startDate) {
                                return "Not Started";
                              } else if (
                                currentDate >= startDate &&
                                currentDate <= endDate
                              ) {
                                return "In Progress";
                              } else {
                                return "Completed";
                              }
                            };

                            // Get the calculated status
                            const status = calculateStatus();

                            return (
                              <tr
                                key={campaign._id}
                                className="border-t border-gray-200 bg-white hover:bg-gray-50"
                                onClick={() => handleView(campaign._id)}
                              >
                                <td className=" text-wrap px-4 py-2">
                                  {campaign.name}
                                </td>
                                <td className="  px-4 py-2">{campaign.game}</td>
                                <td className="px-4 py-2">
                                  {convertUTCtoIST(
                                    adjustStartDate(campaign.startDate),
                                  )}
                                </td>
                                <td className="px-4 py-2">
                                  {convertUTCtoIST(campaign.endDate)}
                                </td>
                                <td className=" flex flex-row items-center justify-start gap-x-1 px-4 py-2">
                                  <CircularProgressbarWithChildren
                                    strokeWidth={10}
                                    className="h-6 w-6"
                                    text=""
                                    styles={{
                                      path: {
                                        stroke: `green`,
                                        strokeLinecap: "round",
                                        transition:
                                          "stroke-dashoffset 0.5s ease 0s",
                                        transform: "rotate(1turn)",
                                        transformOrigin: "center center",
                                      },
                                      // Customize the circle behind the path, i.e. the "total progress"
                                      trail: {
                                        stroke: "#d6d6d6",
                                        strokeLinecap: "butt",
                                        transform: "rotate(1turn)",
                                        transformOrigin: "center center",
                                      },
                                    }}
                                    value={
                                      campaign._id && campaign.gameId ? 100 : 50
                                    }
                                  ></CircularProgressbarWithChildren>
                                  <p className="text-sm font-semibold">
                                    {campaign._id && campaign.gameId
                                      ? "100 %"
                                      : "50 %"}
                                  </p>
                                </td>
                                <td className="px-4 py-2">
                                  <div
                                    className={`flex w-[135px] items-center gap-x-2 text-sm ${getStatusStyle(status)}`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faDotCircle}
                                      className="h-2"
                                    />
                                    <p>{status}</p>
                                  </div>
                                </td>
                                <td className="flex flex-row gap-x-4 px-4 py-2">
                                  <button
                                    className="w-5"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleEdit(campaign._id, campaign);
                                    }}
                                  >
                                    <div
                                      data-tooltip-id="view"
                                      data-tooltip-content="view"
                                      data-tooltip-delay-show={1000}
                                    >
                                      <Eye
                                        color="black"
                                        size={18}
                                        className="mb-1"
                                      />
                                    </div>
                                    <Tooltip
                                      id="view"
                                      className="custom-tooltip"
                                      noArrow
                                      place="bottom"
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "Poppins",
                                      }}
                                    />
                                  </button>
                                  <button
                                    className="w-5"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowDelete(!showdelete);
                                      setDeleteId(campaign._id);
                                    }}
                                  >
                                    <div
                                      data-tooltip-id="delete"
                                      data-tooltip-content="Delete"
                                      data-tooltip-delay-show={1000}
                                    >
                                      <LazyLoadImages
                                        src={`${REACT_APP_S3_URL}/assets/icons/delete.webp`}
                                        className="mb-2 h-5"
                                        alt="delete"
                                      />
                                    </div>
                                    <Tooltip
                                      id="delete"
                                      className="custom-tooltip"
                                      hidden={showdelete}
                                      noArrow
                                      place="bottom"
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "Poppins",
                                      }}
                                    />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCopy(campaign._id);
                                    }}
                                  >
                                    <div
                                      data-tooltip-id="Copy Link"
                                      data-tooltip-content="Copy Link"
                                      data-tooltip-delay-show={1000}
                                    >
                                      <Link className="mb-1 h-4 w-4" />
                                    </div>
                                    <Tooltip
                                      id="Copy Link"
                                      className="custom-tooltip"
                                      noArrow
                                      place="bottom"
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "Poppins",
                                      }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {showdelete && (
            <DeleteProject
              id={deleteId}
              close={() => setShowDelete(false)}
            ></DeleteProject>
          )}
          <ToastContainer />
        </div>
      )}
      {loading ? <Loader /> : <Outlet />}
    </>
  );
};

export default Campaigns;
