import React from "react";
import { Link } from "react-router-dom";
import LazyLoadImages from "./LazyLoadImages";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "../styles/Sections/Game.css";
import axios from "axios";

const {
  REACT_APP_S3_URL,
  REACT_APP_CUSTOM_SPIN_URL,
  REACT_APP_CUSTOM_QUIZ_URL,
} = process.env;

interface GameData {
  title: string;
  imageSrc: string;
  link: string;
  customLink?: string;
}

const gameData: GameData[] = [
  {
    title: "Memory Game",
    imageSrc: `${REACT_APP_S3_URL}/assets/memory.webp`,
    link: "https://memory-game.workplay.digital/",
  },
  {
    title: "Find the Object Game",
    imageSrc: `${REACT_APP_S3_URL}/assets/find-the-object.webp`,
    link: "https://find-the-object.workplay.digital/",
  },
  {
    title: "JigSaw Puzzle",
    imageSrc: `${REACT_APP_S3_URL}/assets/puzzle.webp`,
    link: "https://jigsaw-puzzle.workplay.digital/",
  },
  {
    title: "Quiz Game",
    imageSrc: `${REACT_APP_S3_URL}/assets/quiz.webp`,
    link: "https://quiz.workplay.digital/",
    customLink: `${REACT_APP_CUSTOM_QUIZ_URL}?source=game`,
  },
  {
    title: "Slot Machine",
    imageSrc: `${REACT_APP_S3_URL}/assets/slotmachine.webp`,
    link: "https://slot-machine.workplay.digital/",
  },
  {
    title: "Spin the Wheel",
    imageSrc: `${REACT_APP_S3_URL}/assets/spinwheel.webp`,
    link: "https://spin-the-wheel.workplay.digital/",
    customLink: `${REACT_APP_CUSTOM_SPIN_URL}?source=game`,
  },
  {
    title: "Tic-tac-toe",
    imageSrc: `${REACT_APP_S3_URL}/assets/tic-tac-toe.webp`,
    link: "https://tic-tac-toe.workplay.digital/",
  },
  {
    title: "Spot the difference",
    imageSrc: `${REACT_APP_S3_URL}/assets/spot-difference.webp`,
    link: "https://spot-the-difference.workplay.digital/",
  },
  {
    title: "Roll the Dice",
    imageSrc: `${REACT_APP_S3_URL}/assets/dice.webp`,
    link: "https://roll-the-dice.workplay.digital/",
  },
];

const Game: React.FC = () => {
  const handleCustomizeClick = async (gameTitle: string) => {
    try {
      const gameType =
        gameTitle === "Spin the Wheel" ? "spin-the-wheel" : "quiz";
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/game-count?game=${gameType}`,
      );
      window.open(
        gameType === "spin-the-wheel"
          ? REACT_APP_CUSTOM_SPIN_URL
          : REACT_APP_CUSTOM_QUIZ_URL,
        "_blank",
      );
    } catch (error) {
      console.error("Error posting game count:", error);
    }
  };

  const renderGameCard = (game: GameData) => (
    <div
      id="game"
      className="game-card mb-16 h-72 w-72 cursor-pointer rounded-3xl border-4 border-black bg-white pt-6 max-[480px]:drop-shadow-none max-[350px]:mb-12 max-[350px]:h-60 max-[350px]:w-64 xsm:w-80 sm:h-80 sm:w-96 sm:px-5"
      key={game.title}
    >
      <h2 className="mb-5 text-xl font-bold uppercase max-[350px]:text-lg sm:text-2xl">
        {game.title}
      </h2>
      <LazyLoadImages
        className="m-auto h-40 max-[350px]:h-28 sm:h-48"
        src={game.imageSrc}
        alt={game.title}
      />
      <div className="game-button mt-6 flex items-center justify-center gap-x-2 lg:mt-5">
        <Link to={game.link} target="_blank" rel="noopener noreferrer">
          <button className="w-28 cursor-pointer rounded-md bg-[#65dbee] py-2 font-medium hover:bg-[#4d4c4c] hover:text-white sm:w-36 sm:text-lg">
            Play Now
          </button>
        </Link>
        {game.customLink && (
          <Link to={game.customLink} target="_blank" rel="noopener noreferrer">
            <button
              className="w-28 cursor-pointer rounded-md bg-[#65dbee] py-2 font-medium hover:bg-[#4d4c4c] hover:text-white sm:w-36 sm:text-lg"
              onClick={() => handleCustomizeClick(game.title)}
            >
              Customize
            </button>
          </Link>
        )}
      </div>
    </div>
  );

  const personalizedGames = gameData.filter((game) => game.customLink);
  const playGames = gameData.filter((game) => !game.customLink);

  return (
    <>
      <Navbar />
      <section className="bg-[#ffda24] p-8 pb-1 text-center text-black sm:px-10 sm:py-16 lg:p-16 xl:px-32 xl:py-16">
        <h1 className="game-title m-auto max-w-72 pb-3 pt-3 text-[1.8rem] font-bold leading-10 text-black xsm:pb-6 xsm:text-[2.1rem] sm:max-w-full lg:mb-12 xl:text-heading">
          Personalize Your Game
        </h1>
        <div className="my-10 flex items-center justify-center">
          <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-20 lg:gap-y-24">
            {personalizedGames.map(renderGameCard)}
          </div>
        </div>
        <div className="lg:pb-10"></div>
        <h1 className="game-title -mt-6 pb-3 text-[1.8rem] font-bold leading-10 text-black max-[350px]:-mt-16 xsm:pb-6 xsm:text-[2.1rem] lg:mb-12 xl:text-heading">
          Our Most Popular Games
        </h1>
        <div className="my-10 flex items-center justify-center">
          <div className="lg:grid lg:grid-cols-2  lg:items-center lg:gap-x-20 lg:gap-y-8">
            {playGames.map(renderGameCard)}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Game;
