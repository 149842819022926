import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface MiniLineAreaChartProps {
  dataKey: "Impressions" | "Submissions";
  filter: string;
  data: any;
}

const MiniLineAreaChart: React.FC<MiniLineAreaChartProps> = ({
  dataKey,
  filter,
  data,
}) => {
  return (
    <ResponsiveContainer width="100%" height={50}>
      <AreaChart data={data}>
        <XAxis dataKey="date" hide />
        <YAxis hide />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={dataKey}
          stroke="#ee8251"
          fill="#ee8251"
          fillOpacity={0.3}
          dot={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default MiniLineAreaChart;
