import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Data from "../../../data/analytics.json";

interface ChartData {
  date: string;
  Impressions: number;
  Submissions: number;
}

interface AreaGraphProps {
  data: ChartData[];
  dataKey: "Impressions" | "Submissions";
  filter: string;
}

const AreaGraph: React.FC<AreaGraphProps> = ({ data, dataKey, filter }) => {
  const getColor = () => {
    if (dataKey === "Impressions") {
      return { stroke: "#ee8251", fill: "#ee8251" };
    } else if (dataKey === "Submissions") {
      return { stroke: "#82ca9d", fill: "#82ca9d" };
    }
    return { stroke: "#8884d8", fill: "#8884d8" };
  };

  const { stroke, fill } = getColor();

  return (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey={dataKey} stroke={stroke} fill={fill} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaGraph;
