import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

interface Image {
  source: string;
  link: string;
}
interface socials {
  twitter: string;
  facebook: string;
  github: string;
  instagram: string;
  linkedin: string;
}
interface Company {
  name: string;
  url: string;
}

interface user {
  email: string;
  fname: string;
  lname: string;
  phone: string;
  isdcode: string;
  img: Image;
  bio: string;
  location: string;
  socials: socials;
  company: Company;
}

interface UserContextType {
  user: user;
  setUser: React.Dispatch<
    React.SetStateAction<{
      email: string;
      fname: string;
      lname: string;
      phone: string;
      isdcode: string;
      img: Image;
      bio: string;
      location: string;
      socials: socials;
      company: Company;
    }>
  >;
}

export const userContext = createContext<UserContextType>({
  user: {
    email: "",
    fname: "",
    lname: "",
    phone: "",
    isdcode: "",
    img: { source: "", link: "" },
    bio: "",
    location: "",
    socials: {
      twitter: "",
      facebook: "",
      github: "",
      instagram: "",
      linkedin: "",
    },
    company: {
      name: "",
      url: "",
    },
  },
  setUser: () => {},
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState({
    email: "",
    fname: "",
    lname: "",
    phone: "",
    isdcode: "",
    img: { source: "", link: "" },
    bio: "",
    location: "",
    socials: {
      twitter: "",
      facebook: "",
      github: "",
      instagram: "",
      linkedin: "",
    },
    company: {
      name: "",
      url: "",
    },
  });

  const handleSetUser = (data: any) => {
    setUser({
      email: data.email,
      fname: data.firstName,
      lname: data.lastName,
      phone: data.phoneNumber,
      isdcode: data.isdCode,
      img: {
        source: data.img.source,
        link:
          data.img.source === "bucket"
            ? `${REACT_APP_S3_URL}/uploads/profile/${data.img.link}`
            : data.img.link,
      },
      bio: data?.bio || "",
      location: data?.location || "",
      socials: {
        twitter: data.socials?.twitter || "",
        facebook: data.socials?.facebook || "",
        github: data.socials?.github || "",
        instagram: data.socials?.instagram || "",
        linkedin: data.socials?.linkedin || "",
      },
      company: {
        name: data.company?.name || "",
        url: data.company?.url || "",
      },
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("xSudTzMYmd");
    const fetchProfile = async () => {
      const URL = `${REACT_APP_API_URL}/auth/profile`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
          },
        });

        if (response.data.success === true) {
          handleSetUser(response.data.data);
          localStorage.setItem("Profile", JSON.stringify(response.data.data));
          localStorage.setItem("name", response.data.data.firstName);
          if (response.data.data.img.source === "oauth") {
            localStorage.setItem("ImgLink", response.data.data.img.link);
          }

          if (response.data.data.img.source === "bucket") {
            localStorage.setItem(
              "ImgLink",
              `${REACT_APP_S3_URL}/uploads/profile/${response.data.data.img.link}`,
            );
          }
        }
      } catch (error: any) {
        localStorage.clear();
        console.log(error);
      }
    };
    if (token) {
      fetchProfile();
    }
  }, []);

  return (
    <userContext.Provider value={{ user, setUser }}>
      {children}
    </userContext.Provider>
  );
};

export default UserContextProvider;
