import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import countries from "country-data";

interface CountryData {
  totalImpressions: number;
  country: string;
}

interface CountryPieChartProps {
  data: CountryData[] | null;
  colors: string[];
}

const CountryPieChart: React.FC<CountryPieChartProps> = ({ data, colors }) => {
  function getCountryFullName(countryCode: string) {
    const country = countries.countries[countryCode];
    return country ? country.name : "Unknown";
  }

  const transformedData = data?.map((item) => ({
    totalImpressions: item.totalImpressions,
    country: getCountryFullName(item.country),
  }));

  return (
    <div>
      <h2 className="mb-4 text-center font-medium">Impressions by Country</h2>
      <div className="h-[350px] w-full lg:h-[250px]">
        {data?.length ? (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={transformedData}
                dataKey="totalImpressions"
                nameKey="country"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill={colors[0]}
                label
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <div className="rounded-lg bg-white p-4 text-center shadow">
            {" "}
            No impressions till now
          </div>
        )}
      </div>
    </div>
  );
};

export default CountryPieChart;
