import React from "react";
import { Code, Link, Globe } from "react-feather";
import { AiOutlineQrcode } from "react-icons/ai";

interface FeatureProps {
  icon: React.ElementType;
  title: string;
  description: string;
  comingSoon?: boolean;
}

const Feature: React.FC<FeatureProps> = ({
  icon: IconComponent,
  title,
  description,
  comingSoon,
}) => (
  <div className="integration-card flex flex-col items-center text-center">
    {comingSoon && (
      <div className="ribbon ribbon-top-right">
        <span>Coming Soon</span>
      </div>
    )}
    <div className="mb-6 rounded-full bg-[#ececec] p-4 shadow-md">
      <IconComponent className="text-[#000000]" size={32} />
    </div>
    <h3 className="mb-2 text-lg font-bold">{title}</h3>
    <p className="font-semibold text-[#000000bd]">{description}</p>
  </div>
);

const Integration: React.FC = () => {
  const features = [
    {
      icon: Code,
      title: "Embed",
      description:
        "Seamlessly integrate customized games into any website or landing page.",
    },
    {
      icon: Link,
      title: "Link",
      description: "Share your customized game link on any platform.",
    },
    {
      icon: AiOutlineQrcode,
      title: "QR Code",
      description: "Download the customized game link as a QR code.",
    },
    {
      icon: Globe,
      title: "Custom Domains",
      description:
        "Host customized games on your own (sub)domain for a branded experience.",
      comingSoon: true,
    },
  ];

  return (
    <section
      id="our-services"
      className="bg-[#e2e2e2] px-8 py-10 text-center md:px-16 md:py-14 lg:px-32"
    >
      <h1 className="title mb-6 pb-3 text-[2rem] font-bold text-eerie-black xsm:text-[2.2rem] sm:mb-10 sm:pb-7 sm:text-4xl xl:text-heading">
        Integration
      </h1>
      <div className="flex h-auto items-center justify-center">
        <div className="mx-auto mt-6 grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
          {features.map((feature, index) => (
            <Feature
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              comingSoon={feature.comingSoon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Integration;
