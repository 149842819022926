import FooterSection from "./Footer";
import Navbar from "./Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <section
        id="contact"
        className="h-auto min-h-screen bg-[#f1f1f1] px-6 py-10 text-center font-bold text-black xsm:p-10 sm:px-10 sm:py-16 lg:p-16 xl:px-32 xl:py-12 3xl:px-72 3xl:py-16"
      >
        <h1 className="pb-3 text-3xl text-black sm:text-4xl xl:pb-5">
          Privacy policy
        </h1>
        <div className="text-left font-normal sm:my-10">
          <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
            Introduction
          </h2>
          <p className="text-base">
            At{" "}
            <a
              href="https://workplay.digital/"
              target="_blank"
              rel="noopener noreferrer"
              className="break-all font-medium underline"
            >
              https://workplay.digital/
            </a>
            , accessible from{" "}
            <a
              href="https://workplay.digital/"
              target="_blank"
              rel="noopener noreferrer"
              className="break-all font-medium underline"
            >
              https://workplay.digital/
            </a>
            , one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by{" "}
            <a
              href="https://workplay.digital/"
              target="_blank"
              rel="noopener noreferrer"
              className="break-all font-medium underline"
            >
              https://workplay.digital/
            </a>{" "}
            and how we use it.
          </p>
          <p className="my-2">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
          <p className="my-2">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in{" "}
            <a
              href="https://workplay.digital/"
              target="_blank"
              rel="noopener noreferrer"
              className="break-all font-medium underline"
            >
              https://workplay.digital/
            </a>
            .This policy is not applicable to any information collected offline
            or via channels other than this website.
          </p>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              Consent
            </h2>
            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
          </div>
          <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
            Information we collect
          </h2>
          <p className="my-2">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p className="my-2">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, and the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              How we use your information
            </h2>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <ul className="ml-4 list-disc">
              <li className="mt-2">
                Provide, operate, and maintain our website.
              </li>
              <li>Improve, personalize, and expand our website.</li>
              <li>Understand and analyze how you use our website</li>
              <li>
                Develop new products, services, features, and functionality.
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes .
              </li>
              <li>Send you emails.</li>
              <li>Find and prevent fraud.</li>
            </ul>
          </div>
          <div>
            <h2 className="my-3 text-lg font-semibold">Log Files:</h2>
            <p>
              https://workplay.digital/ follows a standard procedure of using
              log files. These files log visitors when they visit websites. All
              hosting companies do this and a part of hosting services'
              analytics. The information collected by log files include internet
              protocol (IP) addresses, browser type, Internet Service Provider
              (ISP), date and time stamp, referring/exit pages, and possibly the
              number of clicks. These are not linked to any information that is
              personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users' movement
              on the website, and gathering demographic information
            </p>
          </div>
          <div>
            <h2 className="my-3 text-lg font-semibold">
              Cookies and Web Beacons:
            </h2>
            <p>
              Like any other website,{" "}
              <a
                href="https://workplay.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://workplay.digital/
              </a>{" "}
              uses 'cookies'. These cookies are used to store information
              including visitors' preferences, and the pages on the website that
              the visitor accessed or visited. The information is used to
              optimize the users' experience by customizing our web page content
              based on visitors' browser type and/or other information. For more
              information about cookies, and how to disable cookies, visit{" "}
              <a
                href="http://www.allaboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                http://www.allaboutcookies.org
              </a>{" "}
              .
            </p>
          </div>
          <div>
            <h2 className="my-3 text-lg font-semibold">
              Google DoubleClick DART Cookie:
            </h2>
            <p>
              Google is one of a third-party vendor on our site. It also uses
              cookies, known as DART cookies, to serve ads to our site visitors
              based upon their visit to{" "}
              <a
                href="https://workplay.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://workplay.digital/
              </a>{" "}
              and other sites on the internet. However, visitors may choose to
              decline the use of DART cookies by visiting the Google ad and
              content network Privacy Policy at the following URL –{" "}
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://policies.google.com/technologies/ads
              </a>
            </p>
          </div>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              Our Advertising Partners
            </h2>
            <p>
              Some of advertisers on our site may use cookies and web beacons.
              Our advertising partners are listed below. Each of our advertising
              partners has their own Privacy Policy for their policies on user
              data. For easier access, we hyperlinked to their Privacy Policies
              below.
            </p>
            <ul className="ml-4 list-disc">
              <li>
                Google -{" "}
                <a
                  href="https://policies.google.com/technologies/ads"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="break-all font-medium underline"
                >
                  https://policies.google.com/technologies/ads
                </a>{" "}
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              Advertising Partners Privacy Policies
            </h2>
            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of{" "}
              <a
                href="https://workplay.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://workplay.digital/
              </a>{" "}
              . Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on{" "}
              <a
                href="https://workplay.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://workplay.digital/
              </a>{" "}
              , which are sent directly to users' browser. They automatically
              receive your IP address when this occurs. These technologies are
              used to measure the effectiveness of their advertising campaigns
              and/or to personalize the advertising content that you see on
              websites that you visit. <br />
            </p>
            <p className="my-1">
              Note that{" "}
              <a
                href="https://workplay.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://workplay.digital/
              </a>{" "}
              has no access to or control over these cookies that are used by
              third-party advertisers
            </p>
          </div>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              Third Party Privacy Policies
            </h2>
            <p>
              <a
                href="https://workplay.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://workplay.digital/
              </a>
              's Privacy Policy does not apply to other advertisers or websites.
              Thus, we are advising you to consult the respective Privacy
              Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options.
            </p>
            <p className="my-1">
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers'
              respective websites.
            </p>
          </div>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </h2>
            <p className="my-1">
              Under the CCPA, among other rights, California consumers have the
              right to:
            </p>
            <ul className="ml-4 list-disc">
              <li>
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </li>
              <li>
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </li>
              <li>
                Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
              </li>
            </ul>
            <p className="my-1">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>
          </div>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              GDPR Data Protection Rights
            </h2>
            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <ul className="my-1 ml-4 list-disc">
              <li>
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </li>
              <li>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </li>
              <li>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </li>
              <li>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>
            <p>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>
          </div>
          <div>
            <h2 className="mb-3 mt-6 text-xl font-semibold sm:text-2xl">
              Children's Information
            </h2>
            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.{" "}
              <a
                href="https://workplay.digital/"
                target="_blank"
                rel="noopener noreferrer"
                className="break-all font-medium underline"
              >
                https://workplay.digital/
              </a>{" "}
              does not knowingly collect any Personal Identifiable Information
              from children under the age of 13. If you think that your child
              provided this kind of information on our website, we strongly
              encourage you to contact us immediately and we will do our best
              efforts to promptly remove such information from our records.
            </p>
          </div>
        </div>
      </section>
      <FooterSection />
    </>
  );
};

export default PrivacyPolicy;
