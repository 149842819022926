import { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { LoginContext } from "../Context/LoginContext/LoginContext";
import { userContext } from "../Context/LoginContext/UserContext";
import DashBoardHeader from "./DashBoardHeader";
import SideBar from "./Sidebar/SideBar";
import axios from "axios";
import Loader from "../Loader";
import DashBoardHome from "./DashboardHome";
import OutletLoader from "../OutletLoader";
import Banner from "./Banner";

interface DashBoardProps {}

const { REACT_APP_API_URL, REACT_APP_S3_URL } = process.env;

const DashBoard: React.FC<DashBoardProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const contextValue = useContext(LoginContext);
  const { user, setUser } = useContext(userContext);
  const [loading, setLoading] = useState(true);
  const [outletLoading, setOutletLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const handleSetUser = (data: any) => {
    setUser({
      email: data.email,
      fname: data.firstName,
      lname: data.lastName,
      phone: data.phoneNumber,
      isdcode: data.isdCode,
      img: {
        source: data.img.source,
        link:
          data.img.source === "bucket"
            ? `${REACT_APP_S3_URL}/uploads/profile/${data.img.link}`
            : data.img.link,
      },
      bio: data.bio,
      location: data.location,
      socials: data.socials,
      company: data.company,
    });
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("xSudTzMYmd");
      const URL = `${REACT_APP_API_URL}/auth/profile`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success === true) {
          contextValue?.login();
          handleSetUser(response.data.data);
          if (response.data.data.img.source === "oauth") {
            sessionStorage.setItem("ImgLink", response.data.data.img.link);
            sessionStorage.setItem("fname", response.data.data.firstName);
            sessionStorage.setItem("lname", response.data.data.lastName);
            sessionStorage.setItem("email", response.data.data.email);
          }

          if (response.data.data.img.source === "bucket") {
            sessionStorage.setItem(
              "ImgLink",
              `${REACT_APP_S3_URL}/uploads/profile/${response.data.data.img.link}`,
            );
            sessionStorage.setItem("fname", response.data.data.firstName);
            sessionStorage.setItem("lname", response.data.data.lastName);
            sessionStorage.setItem("email", response.data.data.email);
          }
        } else {
          contextValue?.logout();
          localStorage.removeItem("xSudTzMYmd");
          sessionStorage.removeItem("ImgLink");
          sessionStorage.removeItem("lname");
          sessionStorage.removeItem("fname");
          sessionStorage.removeItem("email");
          navigate("/login");
        }
      } catch (error: any) {
        contextValue?.logout();
        localStorage.removeItem("xSudTzMYmd");
        sessionStorage.removeItem("ImgLink");
        sessionStorage.removeItem("lname");
        sessionStorage.removeItem("fname");
        sessionStorage.removeItem("email");
        navigate("/login");
        console.log(error);
      } finally {
        setLoading(false);
        setTimeout(() => setShowBanner(true), 400);
      }
    };
    fetchProfile();
  }, []);

  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     if (!loading) {
  //       setOutletLoading(true);
  //       setTimeout(() => {
  //         setOutletLoading(false);
  //       }, 1000);
  //     }
  //   };

  //   handleRouteChange();
  // }, [location]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex h-svh flex-row overflow-y-hidden">
          <SideBar />
          <div className="flex-1 flex-col">
            <DashBoardHeader />
            <div
              id="mainContent"
              className={`z-20 h-screen flex-1 overflow-auto bg-[#f6f6f7] pb-20`}
            >
              {outletLoading ? (
                <OutletLoader />
              ) : (
                <>
                  {location.pathname !== "/dashboard" ? (
                    <div>
                      <Outlet />
                      {showBanner && <Banner />}
                    </div>
                  ) : (
                    <DashBoardHome />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashBoard;
